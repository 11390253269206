import React, { useCallback, useState } from 'react';
import { showModal } from 'shared/foreground/actions/modals';
import { globalState } from 'shared/foreground/models';
import { queueJob } from 'shared/foreground/stateUpdaters/persistentStateUpdaters/jobs';
import { createToast } from 'shared/foreground/toasts.platform';
import combineClasses from 'shared/foreground/utils/combineClasses';
import copyTextToClipboard from 'shared/foreground/utils/copyTextToClipboard';
import { DisplayTheme, JobType } from 'shared/types';
import { BrowserType, getCurrentBrowser, isDesktopApp, os } from 'shared/utils/environment';
import getServerBaseUrl from 'shared/utils/getServerBaseUrl.platform';

import Button from '../Button';
import ChevronDownIcon from '../icons/20StrokeChevronDownSmall';
import BookmarkletIcon from '../icons/24StrokeBookmarkletIcon';
import AndroidIcon from '../icons/AndroidIcon';
import AppleIcon from '../icons/AppleIcon';
import MacFinderIcon from '../icons/MacFinderIcon';
import WindowsIcon from '../icons/WindowsIcon';
import { AccountSettingsPage } from './AccountSettingsPage';
import accountSettingsStyles from './AccountSettingsPage.module.css';
import styles from './ResourcesPage.module.css';

export function ResourcesPage() {
  return (
    <AccountSettingsPage title="Resources">
      <Resources />
    </AccountSettingsPage>
  );
}

const Bookmarklet = () => {
  const [showInstructions, setShowInstructions] = useState(false);

  const bookmarkletContent = `javascript:(function(){
    open(
      '${getServerBaseUrl()}/save?title=' + encodeURIComponent(document.title)
      + '&url=' + encodeURIComponent(location.href)
    )
  })()`.replace(/\s+/g, '');

  const copyBookmarkletCode = () => {
    copyTextToClipboard(bookmarkletContent);
  };

  return (
    <div className={accountSettingsStyles.list}>
      <div className={combineClasses(accountSettingsStyles.listItem, styles.bookmarkletWrapper)}>
        <div className={styles.bookmarkletInstructions}>
          <div className={combineClasses(accountSettingsStyles.left, styles.left)}>
            <div className={accountSettingsStyles.imageWrapper}>
              <BookmarkletIcon />
            </div>
            <div className={accountSettingsStyles.textWrapper}>
              <div className={accountSettingsStyles.title}>
                <h1>Bookmarklet</h1>
              </div>
              <div className={combineClasses(accountSettingsStyles.subtitle, styles.subtitle)}>
                Add web documents to Reader with a special bookmark. This method does not support
                highlighting or note taking on the open web.
              </div>
            </div>
          </div>

          <div className={combineClasses(accountSettingsStyles.right, styles.right)}>
            <Button
              className={styles.toggleInstructions}
              variant="unstyled"
              onClick={(e: Event) => {
                e.preventDefault();
                setShowInstructions(!showInstructions);
              }}
            >
              {showInstructions ? 'Hide instructions' : 'Show instructions'}
              <ChevronDownIcon
                className={`${styles.chevronDownIcon} ${showInstructions ? styles.rotate : ''}`}
              />
            </Button>
          </div>
        </div>

        {showInstructions && (
          <div className={styles.instructionsWrapper}>
            <div className={styles.installing}>
              <div className={styles.smallBorder} />
              <h1>Installing</h1>
              <p>Drag and drop the link below to your bookmarks folder.</p>
              <Button
                onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => e.preventDefault()}
                to={bookmarkletContent}
                variant="primary"
              >
                Add to Reader
              </Button>
              <div className={styles.smallBorder} />
            </div>
            <div>
              <p>
                Or, to install manually, create a new bookmark and paste the following into the URL field
              </p>
              <div className={styles.inputWithButton}>
                <input
                  onClick={copyBookmarkletCode}
                  aria-labelledby="bookmarklet code"
                  readOnly
                  value={bookmarkletContent}
                  autoComplete="off"
                />
                <Button variant="secondary" onClick={copyBookmarkletCode}>
                  Copy
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const Resources = () => {
  const isDarkMode = globalState(
    useCallback((state) => state.webEffectiveTheme === DisplayTheme.Dark, []),
  );
  const currentBrowser = getCurrentBrowser();

  const sendAppEmail = async (platform: 'ios' | 'android') => {
    await queueJob({
      jobType: JobType.GenerateEmailForAppInstall,
      jobArguments: { forceSend: true, platform },
      options: { userInteraction: 'click' },
    });
    createToast({ content: `Installation email sent`, category: 'success' });
  };

  const openQrModal = (platform: 'ios' | 'android') => {
    if (platform === 'ios') {
      showModal({ id: 'ios-store-qr' }, { userInteraction: 'unknown' });
    } else {
      showModal({ id: 'android-store-qr' }, { userInteraction: 'unknown' });
    }
  };

  return (
    <div className={combineClasses(styles.wrapper, isDarkMode ? styles.isDarkMode : '')}>
      <div className={styles.box}>
        <h2 className={styles.boxTitle}>Readwise browser extension</h2>
        <p className={styles.boxSubtitle}>
          The browser extension allows you to save web pages to Reader, as well as (optionally) highlight
          and take notes on the open web. Annotations sync bi-directionally between Reader and the web
          and are then pushed to other connected services like Notion, Obsidian, Logseq, Roam Research,
          or Evernote (if connected in Readwise).
        </p>
        <div className={styles.extensionLinks}>
          <a
            className={`${styles.extensionLink} ${
              currentBrowser === BrowserType.Chrome ? '' : styles.disabled
            }`}
            href="https://chrome.google.com/webstore/detail/readwise-highlighter/jjhefcfhmnkfeepcpnilbbkaadhngkbi"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className={styles.extensionLogo}
              alt="chrome extension logo"
              src="/onboarding/chrome.svg"
            />
            <Button className={styles.extensionInstallButton} variant="secondary">
              Install
            </Button>
          </a>
          <a
            className={`${styles.extensionLink} ${
              currentBrowser === BrowserType.Firefox ? '' : styles.disabled
            }`}
            href="https://addons.mozilla.org/en-US/firefox/addon/readwise-highlighter"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className={styles.extensionLogo}
              alt="firefox extension logo"
              src="/onboarding/firefox.svg"
            />
            <Button className={styles.extensionInstallButton} variant="secondary">
              Install
            </Button>
          </a>
          <a
            className={`${styles.extensionLink} ${
              currentBrowser === BrowserType.Safari ? '' : styles.disabled
            }`}
            href="https://apps.apple.com/app/save-to-reader/id1640236961"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className={styles.extensionLogo}
              alt="safari extension logo"
              src="/onboarding/safari.svg"
            />
            <Button className={styles.extensionInstallButton} variant="secondary">
              Install
            </Button>
          </a>
          <a
            className={`${styles.extensionLink} ${
              currentBrowser === BrowserType.Brave ? '' : styles.disabled
            }`}
            href="https://chrome.google.com/webstore/detail/readwise-highlighter/jjhefcfhmnkfeepcpnilbbkaadhngkbi"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className={styles.extensionLogo}
              alt="brave extension logo"
              src="/onboarding/brave.svg"
            />
            <Button className={styles.extensionInstallButton} variant="secondary">
              Install
            </Button>
          </a>
          <a
            className={`${styles.extensionLink} ${
              currentBrowser === BrowserType.Edge ? '' : styles.disabled
            }`}
            href="https://chrome.google.com/webstore/detail/readwise-highlighter/jjhefcfhmnkfeepcpnilbbkaadhngkbi"
            target="_blank"
            rel="noreferrer"
          >
            <img className={styles.extensionLogo} alt="edge extension logo" src="/onboarding/edge.svg" />
            <Button className={styles.extensionInstallButton} variant="secondary">
              Install
            </Button>
          </a>
        </div>

        {!isDesktopApp && <Bookmarklet />}
      </div>

      <div className={styles.box}>
        <h2 className={styles.boxTitle}>Mobile apps</h2>
        <div className={styles.apps}>
          <div className={combineClasses(styles.app, styles.mobileApp)}>
            <div className={combineClasses(accountSettingsStyles.left, styles.left)}>
              <AndroidIcon className={styles.androidIcon} />
            </div>
            <div className={combineClasses(accountSettingsStyles.right, styles.right)}>
              <h3 className={combineClasses(accountSettingsStyles.title, styles.title)}>
                Readwise Reader for Android
              </h3>
              <p>
                Text yourself a link to the Google Play store or scan the{' '}
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/click-events-have-key-events */}
                <a onClick={() => openQrModal('android')}>QR Code</a>.
              </p>
              <Button variant="secondary" onClick={() => sendAppEmail('android')}>
                Email link
              </Button>
            </div>
          </div>
          <div className={combineClasses(styles.app, styles.mobileApp)}>
            <div className={combineClasses(accountSettingsStyles.left, styles.left)}>
              <AppleIcon className={styles.appleIcon} />
            </div>
            <div className={combineClasses(accountSettingsStyles.right, styles.right)}>
              <h3 className={combineClasses(accountSettingsStyles.title, styles.title)}>
                Readwise Reader for iOS
              </h3>
              <p>
                Text yourself a link to the Apple App store or scan the{' '}
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/click-events-have-key-events */}
                <a onClick={() => openQrModal('ios')}>QR Code</a>.
              </p>
              <Button variant="secondary" onClick={() => sendAppEmail('ios')}>
                Email link
              </Button>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.box}>
        <h2 className={styles.boxTitle}>Desktop apps</h2>
        <div className={styles.apps}>
          <div
            className={combineClasses(styles.app, os.name === 'Mac OS' ? styles.firstApp : undefined)}
          >
            <div className={combineClasses(accountSettingsStyles.left, styles.left)}>
              <MacFinderIcon text="" />
            </div>
            <div className={combineClasses(accountSettingsStyles.right, styles.right)}>
              <h3 className={combineClasses(accountSettingsStyles.title, styles.title)}>
                Readwise Reader for MacOS
              </h3>
              <Button
                download
                isExternal
                to="https://reader-desktop-releases.readwise.io/Reader.dmg"
                variant="secondary"
              >
                Download now
              </Button>
            </div>
          </div>
          <div
            className={combineClasses(styles.app, os.name === 'Windows' ? styles.firstApp : undefined)}
          >
            <div className={combineClasses(accountSettingsStyles.left, styles.left)}>
              <WindowsIcon text="" />
            </div>
            <div className={combineClasses(accountSettingsStyles.right, styles.right)}>
              <h3 className={combineClasses(accountSettingsStyles.title, styles.title)}>
                Readwise Reader for Windows
              </h3>
              <Button
                download
                isExternal
                to="https://reader-desktop-releases.readwise.io/Reader.exe"
                variant="secondary"
              >
                Download now
              </Button>
            </div>
          </div>
        </div>
      </div>

      <div className={`${styles.box} ${styles.integrationsBox}`}>
        <div className={styles.integrationsDetails}>
          <h2 className={styles.boxTitle}>Integrations</h2>
          <p>Manage your connections to external services.</p>
        </div>

        <div className={styles.integrationsLink}>
          <Button to="/integrations" variant="secondary">
            Go to integrations
          </Button>
        </div>
      </div>

      <div className={`${styles.box} ${styles.integrationsBox}`}>
        <div className={styles.integrationsDetails}>
          <h2 className={styles.boxTitle}>Public API</h2>
          <p>
            You can save new documents to Reader, as well as fetch your document data, using the Reader
            API
          </p>
        </div>

        <div className={styles.integrationsLink}>
          <Button to="https://readwise.io/reader_api" variant="secondary" isExternal target="_blank">
            API documentation
          </Button>
        </div>
      </div>
    </div>
  );
};
